'use strict';

import { GLOBAL } from './Global';
const PERMISSION_GROUPS = require('../PermissionsGroups');

const sales_automation_v2_permissions_list = [];

for (const [key, value] of Object.entries(PERMISSION_GROUPS.SALES_AUTOMATION_V2)) {
  sales_automation_v2_permissions_list.push({
    action_list: value,
    element: key,
  });
}

const SALES_AUTOMATION_V2 = {
  can: [
    ...GLOBAL.can,
    ...sales_automation_v2_permissions_list,
  ],
};

export {
  SALES_AUTOMATION_V2,
};
