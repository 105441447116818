class PosthogRepository {

  /**
   * @param {PostHog|null} posthog posthog instance
   */
  constructor(posthog_instance) {
    this._posthog = posthog_instance; // Posthog instance may be null in constructor, but is injected later by the EventCaptureStore after initialization in AppLayout.vue
    this.is_identified = false;
    this.is_connected_as = null;
  }

  /**
   * Sends an event to PostHog
   *
   * @param {string} event
   * @param {object|null} properties
   * @returns {void}
   */
  capture(event, properties) {
    if (!this.posthog) {
      console.warn('PosthogRepository.capture() failed: capture event triggerred but Posthog instance was not initialized');

      return;
    }

    if (!event) {
      console.error('PosthogRepository.capture() failed: event name is required');

      return;
    }

    try {
      this.posthog.capture(event, properties);
    } catch (error) {
      console.error('PosthogRepository.capture() failed', error);
    }
  }

  /**
   * Call as soon as the current user is known. Links all future events to that user.
   *
   * @param {string} distinct_id a user's unique identifier
   * @param {object|null} properties any additional properties to attach to the user
   * @returns {void}
   */
  identify(distinct_id, properties) {
    if (!this.posthog) {
      console.warn('PosthogRepository.identify() failed: capture event triggerred but Posthog instance was not initialized');

      return;
    }

    if (this.is_identified && this.is_connected_as === properties.connected_as) {
      console.info('PosthogRepository.identify() skipped: user is already identified');

      return;
    }

    if (!distinct_id) {
      console.error('PosthogRepository.identify() failed: distinct_id is required');

      return;
    }

    try {
      this.reset();
      this.posthog.identify(distinct_id, properties);
      this.is_identified = true;
      this.is_connected_as = properties.connected_as;
    } catch (error) {
      console.error('PosthogRepository.identify() failed', error);
    }
  }

  /**
   * Call when the current user logs out. Unlinks any future events from that user.
   *
   * @returns {void}
   */
  reset() {
    if (!this.posthog) {
      console.error('PosthogRepository.reset() failed: capture event triggerred but Posthog instance was not initialized');

      return;
    }

    try {
      this.posthog.reset();
      this.is_identified = false;
      this.is_connected_as = null;
    } catch (error) {
      console.error('PosthogRepository.reset() failed', error);
    }
  }

  get posthog() {
    return this._posthog;
  }

  set posthog(posthog) {
    this._posthog = posthog;
  }
}

const posthog_repository = new PosthogRepository(null);

export {
  posthog_repository,
};
