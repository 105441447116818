import { provide, inject } from 'vue';
import configuration from '@/configurations';
import posthog from 'posthog-js';

const PostHogSymbol = Symbol('PostHog');

function usePostHogProvider() {
  const posthogInstance = posthog.init(
    configuration.posthog.api_token,
    {
      api_host: configuration.posthog.api_host,
      person_profiles: 'identified_only',
      autocapture: true,
      capture_pageview: false,
      capture_pageleave: false,
    }
  );

  provide(PostHogSymbol, posthogInstance);

  return posthogInstance;
}

function usePostHog() {
  const posthogInstance = inject(PostHogSymbol);

  if (!posthogInstance) {
    throw new Error('PostHog has not been provided');
  }

  return posthogInstance;
}

export {
  usePostHog,
  usePostHogProvider,
};
