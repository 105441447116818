export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "advanced_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche avancée"])},
        "business_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes business"])},
        "campaigns_and_sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Campagnes & Séquences"])},
        "conferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conférences"])},
        "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramétrages"])},
        "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
        "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
        "dashboard_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez votre activité et vos résultats"])},
        "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licences"])},
        "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes"])},
        "manager_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès manager"])},
        "my_campaigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes campagnes"])},
        "my_lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes listes"])},
        "my_prospects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes prospects"])},
        "my_prospects_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrouvez la synthèse de votre prospection"])},
        "my_sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes séquences"])},
        "prospecting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma prospection"])},
        "prospecting_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de prospection"])},
        "assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan"])},
        "sales_intelligence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales intelligence"])},
        "smart_scoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smart Scoring"])},
        "smart_scoring_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorisez vos actions sur les bons comptes"])},
        "team_tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivi d'équipe"])},
        "user-submenu_link_ai": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon IA"])},
        "user-submenu_link_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes alertes"])},
        "user-submenu_link_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes entreprises suivies"])},
        "user-submenu_link_contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes contacts suivis"])},
        "user-submenu_link_credits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes crédits"])},
        "user-submenu_link_email_connection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion boîte mail"])},
        "user-submenu_link_exports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes exports"])},
        "user-submenu_link_logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
        "user-submenu_link_notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes notifications"])},
        "user-submenu_link_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer mon mot de passe"])},
        "user-submenu_link_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes préférences de notification"])},
        "user-submenu_link_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon parcours"])},
        "user-submenu_link_searches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes recherches sauvegardées"])},
        "user-submenu_link_templates-and-signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes templates et signatures"])},
        "user-submenu_link_test-emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes emails test"])},
        "user-submenu_title_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon paramétrage"])},
        "user-submenu_title_activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon activité"])},
        "user-submenu_title_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon profil"])},
        "user-submenu_my_requests_and_inquiries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes demandes d'enquêtes"])}
      },
      "en": {
        
      }
    }
  })
}
