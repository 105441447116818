<template>
  <div>
    <v-dialog
      v-model="dialog"
      :width="width"
    >
      <template #activator="{ props }">
        <div
          v-bind="props"
        >
          <slot name="trigger" />
        </div>
      </template>

      <section class="help-popup">
        <header class="header">
          <div>
            <h3 class="nds-text-h3">
              {{ $t('contact_form') }}
            </h3>
            <p class="nds-text-p-big">
              {{ $t('answer_delay') }}
            </p>
          </div>

          <svg-element
            icon="close"
            size="16"
            class="close"
            @click="closeModal()"
          />
        </header>

        <main>
          <p class="nds-text-p-bold">
            <svg-element
              icon="phone"
              size="12"
            />
            {{ $t('phone_number') }}
          </p>

          <p class="nds-text-p-bold">
            {{ $t('your_need') }}
          </p>

          <select-element
            v-model="request_type"
            :multi="false"
            class="select-element"
            :options="request_type_option_list"
            :placeholder="request_type_option_list[0].name"
          />

          <p class="nds-text-p-bold">
            {{ $t('your_message') }}
          </p>

          <textarea
            v-model="message"
            rows="5"
          />

          <p class="nds-text-p-bold">
            {{ $t('attach_file') }}
          </p>

          <div v-if="!file_name">
            <label
              for="email_attachment"
              class="custom-input-file"
            >
              <svg-element
                icon="plus"
                class="icon-plus"
                size="12"
              />
              <span>{{ $t('import_file') }}</span>
            </label>
            <input
              id="email_attachment"
              class="input-file"
              type="file"
              name="email_attachment"
              @change="onFilePicked"
            >
          </div>

          <p
            v-if="file_name"
            class="nds-text-p-bold"
          >
            {{ file_name }}
          </p>
        </main>

        <footer class="buttons">
          <button-element
            color="blue-o"
            icon="close"
            :text="$t('cancel')"
            @click="closeModal()"
          />
          <button-element
            icon="paper-plane"
            :text="$t('validate')"
            @click="sendHelpRequest()"
          />
        </footer>
      </section>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ButtonElement from '@/components/elements/ButtonElement.vue';
import SelectElement from '@/components/elements/SelectElement.vue';
import SvgElement from '@/components/elements/SvgElement';

const DEFAULT_MAX_FILE_SIZE_IN_MB = 3;

const MB_IN_BYTES = 1 * 1024 * 1024;

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = DEFAULT_MAX_FILE_SIZE_IN_MB * MB_IN_BYTES;

const REQUEST_TYPE_LIST = [
  'request_type_service',
  'request_type_profile_change',
  'request_type_training',
  'request_type_bug',
];

export default {
  name: 'HelpPopup',
  components: {
    ButtonElement,
    SelectElement,
    SvgElement,
  },
  props: {
    width: {
      default: '500',
      type: String,
    },
    show: Boolean,
  },
  emits: [
    'close',
  ],
  data() {
    return {
      dialog: false,
      file_content: '',
      file_name: '',
      file_type: '',
      message: '',
      request_type: '',
      request_type_option_list: REQUEST_TYPE_LIST.map((request_type) => ({
        name: this.$t(request_type),
        value: this.$t(request_type),
      })),
    };
  },
  computed: {
    ...mapGetters({
      user_data: 'user/userData',
    }),
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.closeModal();
      }
    },
    show(value) {
      this.dialog = value;
      if (!value) {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      triggerToast: 'toast/triggerToast',
      sendHelpMessageToSupport: 'email/sendHelpMessageToSupport',
    }),
    closeModal() {
      this.resetVariables();

      this.$emit('close');

      this.dialog = false;
    },
    readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (res) => {
          resolve(res.target.result);
        };
        reader.onerror = (err) => reject(err);

        reader.readAsText(file);
      });
    },
    resetVariables() {
      this.file_content = '';
      this.file_name = '';
      this.file_type = '';
      this.message = '';
    },
    async onFilePicked(event) {
      const [ file ] = event.target.files;

      if (file.size > DEFAULT_MAX_FILE_SIZE_IN_BYTES) {
        this.triggerToast({
          style: 'error',
          text: this.$t('file_too_big'),
        });

        return null;
      }

      this.file_name = file.name;
      this.file_type = file.type;
      this.file_content = await this.readFile(file);
    },
    sendHelpRequest() {
      if (!this.message) {
        this.triggerToast({
          style: 'error',
          text: this.$t('mandatory_message'),
        });

        return null;
      }

      const data = {
        request_type: this.request_type.value || this.request_type_option_list[0].value,
        message: this.message,
        attachment: (this.file_content ? {
          file_content: this.file_content,
          file_name: this.file_name,
          file_type: this.file_type,
        } : null),
        user_first_name: this.user_data.first_name,
        user_last_name: this.user_data.last_name,
        organization_name: this.user_data.organization.name,
        direct_phone: this.user_data.mobile_phone,
        email: this.user_data.email,
        phone: this.user_data.phone,
      };

      Object.keys(data).map((key) => {
        if (!data[key]) {
          delete data[key];
        }
      });

      this.sendHelpMessageToSupport(data);

      this.triggerToast({
        style: 'success',
        text: this.$t('success'),
      });

      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/constants/variables.module.scss';

i {
  margin-right: 5px;
}

p.nds-text-p-bold {
  margin-bottom: 8px;
  margin-top: 16px;
}
p.nds-text-p-big {
  margin-bottom: 16px;
  margin-top: 16px;
}

.buttons {
  border-top: 0.5px solid $medium-gray;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 16px;
  padding-top: 16px;
}

.close {
  cursor: pointer;
}

.cancel {
  border: 1px solid $blue-haze;
  border-radius: 12px;
  color: $cornflower-blue;
  padding: 8px 12px;
}

.custom-input-file {
  border: 1px dashed $cornflower-blue;
  border-radius: 8px;
  display: block flex;
  padding-bottom: 16px;
  padding-top: 16px;
  justify-content: center;
  gap: 5px;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 14px;
    font-weight: 700;
    color: $cornflower-blue;
  }
}

.header {
  border-bottom: 0.5px solid $medium-gray;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.help-popup {
  background-color: $white;
  border-radius: 12px;
  padding: 24px;
}

.icon-plus {
  --color: #{$cornflower-blue};
}

.input-file {
  display: none;
}

.validate {
  background-color: $cornflower-blue;
  border: 1px solid $cornflower-blue;
  border-radius: 12px;
  color: $white;
  padding: 8px 12px;
}
</style>

<i18n src="@/locales/common.json"></i18n>

<i18n src="@/locales/components/elements/help-popup.json"></i18n>
