import { createRouter, createWebHistory } from 'vue-router';
// import { nextTick } from 'vue';
import defineAbilitiesFor from '@/plugins/ability';
import configuration from '@/configurations';
import store from '@/store';
import cookies from 'vue-cookies';
import {
  GLOBAL,
} from '@/constants/abilities';
import {
  CUSTOM_CAPTURE_EVENTS,
  LICENSE_CONSTANTS,
} from '@/constants';

const routes = [
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import('@/components/pages/LoginPage.vue'),
    meta: {
      layout: 'LoginLayout',
      requires_auth: false,
    },
  },
  {
    path: '/design-list',
    name: 'DesignListPage',
    component: () => import('@/components/pages/DesignListPage.vue'),
    meta: {
      layout: 'DefaultLayout',
      requires_auth: false,
      resource: 'PAGE_SEQUENCE_HOME',
    },
  },
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/components/pages/HomePage.vue'),
    meta: {
      layout: 'AppLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
    beforeEnter: (to, from, next) => {
      return redirectionByLicense(next);
    },
  },
  {
    path: '/campaigns',
    redirect: '/campaigns/results',
  },
  {
    path: '/campaigns/results',
    name: 'CampaignResultPage',
    component: () => import('@/components/pages/campaigns/CampaignResultPage.vue'),
    meta: {
      layout: 'AppLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
  },
  {
    path: '/campaigns/lists/:status',
    name: 'CampaignListPage',
    component: () => import('@/components/pages/campaigns/CampaignListPage.vue'),
    props: (route) => ({
      ...route.params,
      page_type: route.params.status,
    }),
    meta: {
      layout: 'AppLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
  },
  {
    path: '/campaign-details/:campaign_id',
    name: 'CampaignDetailsPage',
    component: () => import('@/components/pages/campaigns/CampaignDetailsPage.vue'),
    props(route) {
      const props = { ...route.params, ...route.query };

      props.campaign_id = Number(props.campaign_id);

      return props;
    },
    meta: {
      layout: 'AppLayout',
      requires_auth: true,
      resource: 'PAGE_CAMPAIGN_HOME',
    },
    children: [
      {
        path: 'results',
        component: () => import('@/components/elements/campaigns/CampaignResultsElement.vue'),
        name: 'CampaignDetailsResultsTab',
        meta: {
          layout: 'AppLayout',
          requires_auth: true,
          resource: 'PAGE_CAMPAIGN_HOME',
        },
      },
      {
        path: 'settings',
        component: () => import('@/components/elements/campaigns/CampaignSettingsElement.vue'),
        name: 'CampaignDetailsSettingsTab',
        meta: {
          layout: 'AppLayout',
          requires_auth: true,
          resource: 'PAGE_CAMPAIGN_HOME',
        },
      },
      {
        path: 'content',
        component: () => import('@/components/elements/campaigns/CampaignContentElement.vue'),
        name: 'CampaignDetailsContentTab',
        meta: {
          layout: 'AppLayout',
          requires_auth: true,
          resource: 'PAGE_CAMPAIGN_HOME',
        },
      },
      {
        path: 'targets',
        component: () => import('@/components/elements/campaigns/CampaignTargetsElement.vue'),
        name: 'CampaignDetailsTargetsTab',
        meta: {
          layout: 'AppLayout',
          requires_auth: true,
          resource: 'PAGE_CAMPAIGN_HOME',
        },
      },
      {
        path: 'summary',
        component: () => import('@/components/elements/campaigns/CampaignSummaryElement.vue'),
        name: 'CampaignDetailsSummaryTab',
        meta: {
          layout: 'AppLayout',
          requires_auth: true,
          resource: 'PAGE_CAMPAIGN_HOME',
        },
      },
    ],
  },
  {
    path: '/sequences',
    redirect: '/sequences/results',
  },
  {
    path: '/sequences/results',
    name: 'SequenceResultPage',
    component: () => import('@/components/pages/sequences/SequenceResultPage.vue'),
    meta: {
      layout: 'AppLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
  },
  {
    path: '/sequences/lists/:status',
    name: 'SequenceListPage',
    component: () => import('@/components/pages/sequences/SequenceListPage.vue'),
    props: (route) => ({
      ...route.params,
      page_type: route.params.status,
    }),
    meta: {
      layout: 'AppLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
  },
  {
    path: '/sequence-details/:sequence_id',
    name: 'SequenceDetailsPage',
    component: () => import('@/components/pages/sequences/SequenceDetailsPage.vue'),
    props(route) {
      const props = { ...route.params, ...route.query };

      props.sequence_id = Number(props.sequence_id);
      props.step_position = Number(props.step_position);

      return props;
    },
    meta: {
      layout: 'AppLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
    children: [
      {
        path: 'steps',
        component: () => import('@/components/elements/sequences/SequenceStepsElement.vue'),
        name: 'SequenceDetailsStepsTab',
        meta: {
          layout: 'AppLayout',
          requires_auth: true,
          resource: 'PAGE_SEQUENCE_HOME',
        },
      },
      {
        path: 'settings',
        component: () => import('@/components/elements/sequences/SequenceParamsElement.vue'),
        name: 'SequenceDetailsSettingsTab',
        meta: {
          layout: 'AppLayout',
          requires_auth: true,
          resource: 'PAGE_SEQUENCE_HOME',
        },
      },
      {
        path: 'targets',
        component: () => import('@/components/elements/sequences/SequenceTargetListElement.vue'),
        name: 'SequenceDetailsTargetsTab',
        meta: {
          layout: 'AppLayout',
          requires_auth: true,
          resource: 'PAGE_SEQUENCE_HOME',
        },
      },
      {
        path: 'record',
        component: () => import('@/components/elements/sequences/SequenceRecordElement.vue'),
        name: 'SequenceDetailsRecordTab',
        meta: {
          layout: 'AppLayout',
          requires_auth: true,
          resource: 'PAGE_SEQUENCE_HOME',
        },
      },
    ],
  },
  {
    path: '/sequences/settings',
    name: 'SequenceSettingsPage',
    component: () => import('@/components/pages/sequences/SequenceSettingPage.vue'),
    props: {
      page_type: 'settings',
    },
    meta: {
      layout: 'AppLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
  },
  {
    path: '/reports',
    name: 'ReportsPage',
    component: () => import('@/components/pages/ReportsPage.vue'),
    meta: {
      layout: 'PersonalSpaceLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
  },
  {
    path: '/personal-space/change-password',
    name: 'ChangePasswordPage',
    component: () => import('@/components/pages/personal-space/ChangePasswordPage.vue'),
    meta: {
      layout: 'PersonalSpaceLayout',
      requires_auth: true,
      resource: 'PERSONAL_SPACE_CHANGE_PASSWORD',
    },
  },
  {
    path: '/personal-space/email-connection',
    name: 'EmailConnectionPage',
    component: () => import('@/components/pages/personal-space/EmailConnectionPage.vue'),
    meta: {
      layout: 'PersonalSpaceLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
  },
  {
    path: '/personal-space/my-ai',
    name: 'MyAiPage',
    component: () => import('@/components/pages/personal-space/MyAiPage.vue'),
    meta: {
      layout: 'PersonalSpaceLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
  },
  {
    path: '/personal-space/exports',
    name: 'MyExportsPage',
    component: () => import('@/components/pages/personal-space/MyExportsPage.vue'),
    meta: {
      layout: 'PersonalSpaceLayout',
      requires_auth: true,
      resource: 'PERSONAL_SPACE_EXPORT',
    },
  },
  {
    path: '/personal-space/exports/:export_id',
    name: 'MyExportPage',
    component: () => import('@/components/pages/personal-space/MyExportsPage.vue'),
    props(route) {
      const props = { ...route.params, ...route.query };

      props.export_id = Number(props.export_id);

      return props;
    },
    meta: {
      layout: 'PersonalSpaceLayout',
      requires_auth: true,
      resource: 'PERSONAL_SPACE_EXPORT',
    },
  },
  {
    path: '/activate-account',
    name: 'ActivateAccountPage',
    component: () => import('@/components/pages/ChangePasswordDisconnectedPage.vue'),
    props: {
      account_activation: true,
    },
    meta: {
      layout: 'DefaultLayout',
      requires_auth: false,
      resource: 'PAGE_CHANGE_PASSWORD',
    },
  },
  {
    path: '/change-password',
    name: 'ChangePasswordDisconnectedPage',
    component: () => import('@/components/pages/ChangePasswordDisconnectedPage.vue'),
    meta: {
      layout: 'DefaultLayout',
      requires_auth: false,
      resource: 'PAGE_CHANGE_PASSWORD',
    },
  },
  {
    path: '/email-signature-generator',
    name: 'EmailSignatureGeneratorPage',
    component: () => import('@/components/pages/emails/EmailSignatureGeneratorPage.vue'),
    meta: {
      layout: 'SignatureLayout',
      requires_auth: true,
      resource: 'PAGE_EMAIL_SIGNATURE_GENERATOR',
    },
  },
  {
    path: '/campaigns/settings',
    name: 'CampaignSettingsPage',
    component: () => import('@/components/pages/campaigns/CampaignSettingPage.vue'),
    props: {
      page_type: 'settings',
    },
    meta: {
      layout: 'AppLayout',
      requires_auth: true,
      resource: 'PAGE_SEQUENCE_HOME',
    },
  },
];

const router = createRouter({
  base: configuration.base_url,
  history: createWebHistory(),
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.fullPath.includes('//')) {
    const corrected_path = to.fullPath.replace(/\/{2,}/g, '/');

    return next({ path: corrected_path, replace: true });
  }

  const redirect = await store.dispatch('user/checkJwtFromUrl');

  if (redirect) {
    const query = { ...to.query };

    delete query.jwt;
    delete query.jwt_external;

    const params = new URLSearchParams(query).toString();
    const new_url = `${window.location.pathname}${params ? `?${params}` : ''}`;

    window.history.replaceState(null, '', new_url);

    return next({
      path: to.path,
      query: query,
      replace: true,
    });
  }

  if (to.meta.requires_auth === false) {
    // If user is already logged in, redirect to root
    if (to.path === '/login'
      && (cookies.get(GLOBAL.COOKIE.JWT) || cookies.get(GLOBAL.COOKIE.CONNECTED_AS))
    ) {
      return next('/');
    }

    return next();
  }

  // I moved next line here to avoid redirectioning by logout in checkJwtFromCookies action
  // ex: login page, design list page are public pages
  await store.dispatch('user/checkJwtFromCookies');
  await store.dispatch('user/loginWithJwtFromCookies');

  // Put a protection to avoid calling authenticated API without token
  if (Object.keys(store.getters['user/tokenData']).length !== 0) {
    await store.dispatch('user/getUserData');
    await store.dispatch('user/searchOrganizationUserList');
    await store.dispatch('user/searchColleagueUserList');
    await store.dispatch('skalin/identify', null, {root:true});

    const abilities = defineAbilitiesFor(store.getters['user/userData']);

    const can_navigate = to.matched.some((route) => {
      return abilities.can('read', route.meta.resource);
    });

    if (!can_navigate) {
      return next('/');
    }
  }

  //capture pageleave event
  store.dispatch(
    'event_capture/captureRouteChange',
    {
      event: CUSTOM_CAPTURE_EVENTS.PAGELEAVE,
    }
  );

  next();
});

router.afterEach((to, from, failure) => {
  if (!failure) {
    //capture pageview event
    store.dispatch('event_capture/captureRouteChange', {
      event: CUSTOM_CAPTURE_EVENTS.PAGEVIEW,
    });
  }
});

/**
 * Redirect to the appropriate page according to the user's license
 *
 * !!Attention: we would need to define redirection for these licenses:
 * - Connecteur CRM
 * - Intranet
 * With those license, user see nothing except navbar on '/' page
 */
const redirectionByLicense = (next) => {
  const user_data = store.getters['user/userData'];
  const license_id = user_data.organization_user_license_list[0].license.id;

  if (license_id === LICENSE_CONSTANTS.SENDER.ID) {
    return next('/personal-space/email-connection');
  } else if (license_id === LICENSE_CONSTANTS.INSIGHT.ID
    || license_id === LICENSE_CONSTANTS.SEARCH_AND_LISTS.ID) {
    window.location.href = `${configuration.desk_v1_host}/advanced-search`;
  } else if (license_id === LICENSE_CONSTANTS.KAM.ID) {
    window.location.href = `${configuration.desk_v1_host}/business-alerts`;
  } else if (
    license_id === LICENSE_CONSTANTS.MARKETING.ID
      || license_id === LICENSE_CONSTANTS.SMART_CAMPAIGN_V2.ID
  ) {
    return next('/campaigns/results');
  }

  next();
};

export default router;
