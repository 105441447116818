import moment from 'moment';

import {
  credit_repository,
} from '@/repositories';

const state = () => ({
  credit_sequence_users: [],
});

const getters = {
  creditSequenceUsers: (state) => state.credit_sequence_users,
};

const actions = {
  getSequenceCreditsForUser({ state }, user_id) {
    const credit = state.credit_sequence_users
      .find((credit) => credit.user_id === user_id);

    return (credit === undefined)
      ? 0
      : credit.amount;
  },
  async getCreditSequenceUsers({ commit }, { organization_id }) {
    const credits = await credit_repository.getCreditSequenceUsers({
      organization_id,
      subscription_last_date: moment().toISOString(),
    });

    commit('set_credit_sequence_users', credits.user_list);
  },
  async getOrganizationCampaignCredits(_, { organization_id }) {
    const organization_campaign_credits = await credit_repository.getOrganizationCampaignCredits({
      organization_id,
    });

    return organization_campaign_credits;
  },
};

const mutations = {
  set_credit_sequence_users(state, credit_sequence_users) {
    state.credit_sequence_users = credit_sequence_users;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
