'use strict';

const CUSTOM_CAPTURE_EVENTS = {
  NAVBAR_LOGO_CLICKED: 'navbar_logo_clicked',
  PAGELEAVE: '$pageleave',
  PAGEVIEW: '$pageview',
};

export {
  CUSTOM_CAPTURE_EVENTS,
};
