import {
  authentication_repository,
  posthog_repository,
  user_repository,
} from '@/repositories';

import store from '@/store';

import {
  CUSTOM_CAPTURE_EVENTS,
  QUERY_CONSTANTS,
} from '@/constants';

const actions = {
  /**
   * Initialize the Posthog repository with the provided Posthog instance, and identify the user.
   * @param {Posthog} posthog_instance
   */
  initPosthogRepository({ dispatch }, posthog_instance) {
    posthog_repository.posthog = posthog_instance;
    dispatch('handleUserLoggedIn', {
      jwt: store.getters['user/token'],
    });
  },

  /**
   * Capture an event and send it to collector. Logs an error if the event is not allowed.
   * @param {object} data
   * @param {string} data.event name of the event
   * @param {object|null} data.properties additional properties to attach to the event
   */
  capture(_, data) {
    const { event, properties } = data;

    if (!isValidEvent(event)) {
      console.error(`eventCaptureStore.capture() failed: Event "${event}" is not a valid event.`);

      return;
    }

    posthog_repository.capture(event, properties);
  },

  // =================BEGIN CUSTOM METHODS==================
  // Add named actions here for events with custom logic.
  // Call capture() with the event name and properties
  // =======================================================

  /**
   *
   * @param {string} event name of the event. Accepts only PAGEVIEW or PAGELEAVE events
   * @param {object|null} properties additional properties to attach to the event
   * @returns {void}
   */
  captureRouteChange({ dispatch }, data) {
    const { event, properties } = data;

    // reject capture: invalid event
    if (![
      CUSTOM_CAPTURE_EVENTS.PAGEVIEW,
      CUSTOM_CAPTURE_EVENTS.PAGELEAVE,
    ].includes(event)) {
      console.error(`EventCaptureStore.captureRouteChange() failed: Event "${event}" is not a valid route change event`);

      return;
    }

    dispatch('capture', { event, properties });
  },

  // ===================END CUSTOM METHODS==================

  /**
   * Handles event capture identification logic when user logs in.
   * @param {string} user_id
   * @param {object} properties
   */
  handleUserLoggedIn(_, data) {
    const { jwt } = data;
    const { user_id } = authentication_repository.decodeToken(jwt);

    // gather necessary data for Posthog identify
    Promise.all([
      user_repository.getUserById(user_id),
      store.dispatch('user/isConnectedAs'),
    ])
      .then(async ([user, connected_as]) => {
        const [license_id, nylas_credentials, user_queries] = await Promise.all([
          store.dispatch('user/getLicenseId', user),
          store.dispatch('nylas/getCredentialByUserId', user.id),
          store.dispatch('query/searchUserQueryListLight', { query_type_id_list: [
            QUERY_CONSTANTS.TYPES.ALERT,
            QUERY_CONSTANTS.TYPES.SEGMENT_CONTACT,
            QUERY_CONSTANTS.TYPES.SEGMENT_ACCOUNT,
            QUERY_CONSTANTS.TYPES.PROSPECTING_CONTACT,
            QUERY_CONSTANTS.TYPES.PROSPECTING_ACCOUNT,
          ]}),
        ]);

        const alerts_queries = user_queries.query_list.filter(
          (query) => query.query_type.id === QUERY_CONSTANTS.TYPES.ALERT
        );
        const prospecting_queries = user_queries.query_list.filter(
          (query) => [
            QUERY_CONSTANTS.TYPES.PROSPECTING_CONTACT,
            QUERY_CONSTANTS.TYPES.PROSPECTING_ACCOUNT,
          ].includes(query.query_type.id)
        );
        const segment_queries = user_queries.query_list.filter(
          (query) => [
            QUERY_CONSTANTS.TYPES.SEGMENT_CONTACT,
            QUERY_CONSTANTS.TYPES.SEGMENT_ACCOUNT,
          ].includes(query.query_type.id)
        );

        const properties = {
          alert_count: alerts_queries.length,
          connected_as,
          license_id,
          nylas_connected: !!nylas_credentials.access_token_exist,
          organization_id: user.organization.id,
          prospecting_lists_count: prospecting_queries.length,
          segment_lists_count: segment_queries.length,
          user_id: user.id,
          user_created_at: user.creation_date,
        };

        posthog_repository.identify(user_id, properties);
      })
      .catch((error) => {
        console.error('eventCaptureStore.handleUserLoggedIn() failed:', error);
      });
  },

  /**
   * Handles event capture identification reset when user logs out.
   */
  handleUserLoggedOut() {
    posthog_repository.reset();
  },
};

/**
* Check if the submitted value is a valid event.
* @param {string} submitted_value
* @returns {boolean} true if the submitted value is a valid event
*/
function isValidEvent(submitted_value) {
  return Object.values(CUSTOM_CAPTURE_EVENTS).includes(submitted_value);
}

export default {
  actions,
  namespaced: true,
};
